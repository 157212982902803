.checkboxes {
  :global {
    .ant-checkbox-wrapper {
      padding: 4px 0;
      display: flex;
      break-inside: avoid;
    }
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;