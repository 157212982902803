@import (reference) '../../styles/media.less';

.page {
  background-color: #f9fbfd;
  padding-bottom: 96px;
}

.header {
  @media @sm {
    background-color: #c6dce7;
  }
}
.headerContent {
  @media @sm {
    display: flex;
    align-items: center;
    background-color: #c6dce7;
    margin-bottom: 48px;
  }
}

.titleWrapper {
  background-color: #c6dce7;

  @media @sm {
    flex: 2;
  }
}
.titleContent {
  display: flex;
  align-items: center;
  padding-top: 24px;
  justify-content: space-between;

  @media @sm {
    justify-content: flex-start;
  }
}

.heading {
  font-size: 32px;
  margin-right: 24px;

  @media @sm {
    font-size: 40px;
  }
}

.headerBottom {
  display: flex;
  padding-top: 24px;
  padding-bottom: 24px;

  @media @sm {
    flex: 1;
  }
}

.steps {
  flex: 1 1 auto;
  font-weight: bold;
  color: black;

  @media @sm {
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    padding: 12px 24px 0 24px;
  }
}
.stepsList {
  padding-left: 1.1em;

  li:not(:last-child) {
    margin-bottom: 0.5em;
  }
}

.headerImg {
  width: 30%;
}

.bottom {
  @media @sm {
    display: flex;
  }
}

.formWrapper {
  margin-bottom: 24px;

  @media @sm {
    width: 532px;
    flex: 0 0 auto;
  }
}
.form {
  @media @sm {
    padding: 48px;
  }
}

.video {
  margin-bottom: 24px;
}

.videoYoutube {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin-bottom: 24px;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.faq {
  @media @sm {
    padding: 0 0 0 48px;
    flex: 1 1 auto;
  }

  img {
    margin-bottom: 24px;
  }

  h3 {
    margin-bottom: 0;
  }
}

.address {
  margin-bottom: 24px;

  :global {
    .ant-form-item {
      margin-bottom: 6px;
    }
    .ant-form-item-label {
      line-height: inherit;
    }

    .ant-form-item-required::before {
      content: '';
    }
    .ant-form-item-label > label::after {
      content: '';
    }
  }
}

.rules {
  margin-top: 48px;
}

.label {
  :global {
    .ant-form-item-label {
      padding: 0;

      > label {
        font-weight: bold;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
}

@primary-color: #006B99;@font-family: 'Roboto', sans-serif;